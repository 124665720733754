export const callingCodes = [
    { value: '+54', countryCode: 'ar', country: 'Argentina +54' },
    { value: '+43', countryCode: 'at', country: 'Austria (Österreich) +43' },
    { value: '+61', countryCode: 'au', country: 'Australia +61' },
    { value: '+32', countryCode: 'be', country: 'Belgium (België) +32' },
    { value: '+387', countryCode: 'ba', country: 'Bosnia and Herzegovina +387' },
    { value: '+55', countryCode: 'br', country: 'Brazil (Brasil) +55' },
    { value: '+359', countryCode: 'bg', country: 'Bulgaria (България) +359' },
    { value: '+1', countryCode: 'ca', country: 'Canada +1' },
    { value: '+56', countryCode: 'cl', country: 'Chile +56' },
    { value: '+86', countryCode: 'cn', country: 'China (中国) +86' },
    { value: '+57', countryCode: 'co', country: 'Colombia +57' },
    { value: '+385', countryCode: 'hr', country: 'Croatia (Hrvatska) +385' },
    { value: '+420', countryCode: 'cz', country: 'Czech Republic (Česká republika) +420' },
    { value: '+45', countryCode: 'dk', country: 'Denmark (Danmark) +45' },
    { value: '+372', countryCode: 'ee', country: 'Estonia (Eesti) +372' },
    { value: '+358', countryCode: 'fi', country: 'Finland (Suomi) +358' },
    { value: '+33', countryCode: 'fr', country: 'France +33' },
    { value: '+995', countryCode: 'ge', country: 'Georgia (საქართველო) +995' },
    { value: '+49', countryCode: 'de', country: 'Germany (Deutschland) +49' },
    { value: '+30', countryCode: 'gr', country: 'Greece (Ελλάδα) +30' },
    { value: '+36', countryCode: 'hu', country: 'Hungary (Magyarország) +36' },
    { value: '+91', countryCode: 'in', country: 'India (भारत) +91' },
    { value: '+62', countryCode: 'id', country: 'Indonesia +62' },
    { value: '+353', countryCode: 'ie', country: 'Ireland +353' },
    { value: '+972', countryCode: 'il', country: 'Israel (ישראל) +972' },
    { value: '+39', countryCode: 'it', country: 'Italy (Italia) +39' },
    { value: '+81', countryCode: 'jp', country: 'Japan (日本) +81' },
    { value: '+7', countryCode: 'kz', country: 'Kazakhstan (Казахстан) +7' },
    { value: '+371', countryCode: 'lv', country: 'Latvia (Latvija) +371' },
    { value: '+370', countryCode: 'lt', country: 'Lithuania (Lietuva) +370' },
    { value: '+352', countryCode: 'lu', country: 'Luxembourg (Lëtzebuerg) +352' },
    { value: '+60', countryCode: 'my', country: 'Malaysia +60' },
    { value: '+52', countryCode: 'mx', country: 'Mexico (México) +52' },
    { value: '+31', countryCode: 'nl', country: 'Netherlands (Nederland) +31' },
    { value: '+64', countryCode: 'nz', country: 'New Zealand (Aotearoa) +64' },
    { value: '+47', countryCode: 'no', country: 'Norway (Norge) +47' },
    { value: '+51', countryCode: 'pe', country: 'Peru (Perú) +51' },
    { value: '+63', countryCode: 'ph', country: 'Philippines (Pilipinas) +63' },
    { value: '+48', countryCode: 'pl', country: 'Poland (Polska) +48' },
    { value: '+351', countryCode: 'pt', country: 'Portugal +351' },
    { value: '+40', countryCode: 'ro', country: 'Romania (România) +40' },
    { value: '+7', countryCode: 'ru', country: 'Russia (Россия) +7' },
    { value: '+381', countryCode: 'rs', country: 'Serbia (Србија) +381' },
    { value: '+65', countryCode: 'sg', country: 'Singapore +65' },
    { value: '+421', countryCode: 'sk', country: 'Slovakia (Slovensko) +421' },
    { value: '+386', countryCode: 'si', country: 'Slovenia (Slovenija) +386' },
    { value: '+27', countryCode: 'za', country: 'South Africa +27' },
    { value: '+82', countryCode: 'kr', country: 'South Korea (한국) +82' },
    { value: '+34', countryCode: 'es', country: 'Spain (España) +34' },
    { value: '+46', countryCode: 'se', country: 'Sweden (Sverige) +46' },
    { value: '+41', countryCode: 'ch', country: 'Switzerland (Schweiz) +41' },
    { value: '+90', countryCode: 'tr', country: 'Turkey (Türkiye) +90' },
    { value: '+380', countryCode: 'ua', country: 'Ukraine (Україна) +380' },
    { value: '+44', countryCode: 'gb', country: 'United Kingdom +44' },
    { value: '+1', countryCode: 'us', country: 'United States +1' },
];

export const countries = [
    "AR",
    "AT",
    "AU",
    "BA",
    "BE",
    "BG",
    "BR",
    "BY",
    "CA",
    "CH",
    "CL",
    "CN",
    "CO",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GE",
    "GR",
    "HR",
    "HU",
    "ID",
    "IE",
    "IL",
    "IN",
    "IT",
    "JP",
    "KR",
    "KZ",
    "LT",
    "LU",
    "LV",
    "MX",
    "MY",
    "NL",
    "NO",
    "PE",
    "PH",
    "PL",
    "PT",
    "RO",
    "RS",
    "RU",
    "SE",
    "SI",
    "SK",
    "TR",
    "UA",
    "US",
  ];